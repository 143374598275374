<template>
  <div>
    <component
      ref="model"
      :is="getComonent(config.modelName || 'Model-1')"
      backdrop="static"
      @ready="modelReady"
      @Hide="onhide"
    >
      <div style="position: relative; min-width: 200px; min-height: 100px">
        <div>
          <h6>场景设置</h6>
        </div>
        <div
          class=""
          style="position: absolute; top: 0; right: 0; padding: 0 5px; cursor: pointer"
          @click="$refs.model.hideModal()"
        >
          <i class="fa fa-times"></i>
        </div>
        <template v-if="editItemIndex >= 0 && form.items[editItemIndex]">
          <div class="form-group has-feedback">
            <label for="Title">标题</label>
            <input
              type="text"
              class="form-control"
              id="Title"
              v-model.trim="form.items[editItemIndex].title"
              placeholder="请输入标题"
            />
          </div>

          <div class="form-group">
            <label for="Text">简介</label>
            <!--<textarea class="form-control" id="Text" v-model.trim="form.text" placeholder="请输入简介"></textarea>-->
            <PellEditor v-model.trim="form.items[editItemIndex].description" />
          </div>
          <div class="form-group">
            <label>场景引导图</label>
            <div>
              <button
                v-if="!form.items[editItemIndex].file"
                type="button"
                class="btn btn-outline-primary"
                @click="showItemFileLibDialog()"
              >
                {{
                  form.items[editItemIndex].file
                    ? `${form.items[editItemIndex].file.name}`
                    : "选择素材"
                }}
              </button>
              <template
                v-if="
                  form.items[editItemIndex].file && form.items[editItemIndex].file.isImage
                "
              >
                <div class="card-tools float-right">
                  <button
                    type="button"
                    class="btn btn-box-tool"
                    @click="form.items[editItemIndex].file = null"
                    title="删除"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <a>
                  <div
                    style="
                      height: 100px;
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-color: #ccc;
                      background-position: center;
                    "
                    :style="{
                      'background-image':
                        'url(' + form.items[editItemIndex].file.url + ')',
                    }"
                  ></div>
                </a>
              </template>
            </div>
          </div>
          <div class="form-group">
            <label>场景解说</label>
            <div>
              <button
                v-if="form.items[editItemIndex].audio"
                type="button"
                class="btn btn-outline-primary mb-1"
                @click="showItemAudioLibDialog()"
              >
                解说:{{ (form.items[editItemIndex].audio || {}).name || null }}
              </button>
              <button
                v-else
                type="button"
                class="btn btn-outline-primary mb-1"
                @click="showItemAudioLibDialog()"
              >
                选择解说
              </button>
              <audio
                v-if="form.items[editItemIndex].audio"
                class="form-control"
                :src="(form.items[editItemIndex].audio || {}).url || null"
                controls
              ></audio>
            </div>
          </div>
          <template v-if="form.items[editItemIndex].audio">
            <div class="form-group custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitchItemAudioAutoPlay"
                v-model.trim="form.items[editItemIndex].audioAutoPlay"
              />
              <label class="custom-control-label" for="customSwitchItemAudioAutoPlay"
                >自动播放</label
              >
            </div>
            <div class="form-group custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customSwitchItemAudioLoop"
                v-model.trim="form.items[editItemIndex].audioLoop"
              />
              <label class="custom-control-label" for="customSwitchItemAudioLoop"
                >循环播放</label
              >
            </div>
          </template>
          <div class="form-group">
            <label>天气效果</label>
            <select class="form-control" v-model="form.items[editItemIndex].weather">
              <option v-for="i in weathers" :value="i.key">{{ i.key }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>分组</label>
            <select
              class="form-control"
              v-model="form.items[editItemIndex].groupGUID"
              @change="groupGUIDChange"
            >
              <option v-for="i in groups" :value="i.guid">
                {{ i.title || "[未分组]" }}
              </option>
            </select>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <!--<button type="button" class="btn btn-sm btn-default mr-2" style="pointer-events:auto" @click.prevent="viewType='hotspot';$refs.editItem.show=false">编辑热点</button>-->
            <button class="btn btn-danger btn-sm mr-2 mt-1" @click="deleteItemshow()">
              删除
            </button>
            <button
              type="button"
              class="btn btn-sm btn-default mr-1 mt-1"
              style="pointer-events: auto"
              @click.prevent="$emit('cover')"
            >
              封面
            </button>
            <button
              type="button"
              class="btn btn-sm btn-default mr-1 mt-1"
              style="pointer-events: auto"
              @click.prevent="$emit('view')"
            >
              视角
            </button>
            <button
              type="button"
              class="btn btn-sm btn-default mr-1 mt-1"
              style="pointer-events: auto"
              @click.prevent="$emit('hotspot')"
            >
              热点<span class="badge badge-pill badge-primary">{{
                publicData.hotspotCount
              }}</span>
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm mt-1"
              @click="$refs.model.hideModal()"
            >
              确定
            </button>
          </div>
        </template>
        <div v-else class="form-group">未选择项目</div>
      </div>
    </component>
    <component
      ref="deleteItem"
      :is="getComonent(config.modelName || 'Model-1')"
      backdrop="static"
      @ready="deleteItemModelReady"
    >
      <div>
        <h5>删除场景</h5>
      </div>
      确定删除此场景？
      <div style="display: flex; justify-content: flex-end">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-2"
          @click="deleteItemModelHide()"
        >
          取消
        </button>
        <button class="btn btn-danger btn-sm" @click="deleteItem(editItemGuid)">
          删除
        </button>
      </div>
    </component>
    <template v-if="urls">
      <FileLib
        ref="ItemFileLib"
        :libUrl="urls.fileLib()"
        :panosUploadUrl="urls.panosUploadUrl()"
        :filesUploadUrl="urls.filesUploadUrl()"
        :tagsUrl="urls.privateTags()"
        :fileUrl="urls.fileUrl()"
        :panoUrl="urls.panoUrl()"
        :params="params"
        @submit="ItemFileLibSelected"
      ></FileLib>
      <AudioLib
        ref="ItemAudioLib"
        :libUrl="urls.audioLib()"
        :onSelected="ItemAudioLibSelected"
      ></AudioLib>
      <PanoLib
        ref="PanoMultipleLib"
        :multiple="true"
        :libUrl="urls.libPanosUrl()"
        :params="params"
        @submit="PanoMultipleLibOnSubmit"
      ></PanoLib>
    </template>
  </div>
</template>
<script>
import { uuid } from "vue-uuid";
import PellEditor from "../../PellEditor";
import FileLib from "../../FileLib";
import PanoLib from "../../PanoLib";
import AudioLib from "../../AudioLib";

export default {
  components: {
    PellEditor,
    FileLib,
    PanoLib,
    AudioLib,
  },
  props: {
    config: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      modelShow: null,
      modelHide: null,
      deleteItemModelShow: null,
      deleteItemModelHide: null,
      weathers: [],
      publicTags: [],
      privateTags: [],
      PanoMultipleLibOnSubmit: () => {},
    };
  },
  inject: {
    publicData: {
      default: {},
    },
    getFunc: {},
    getComonent: {},
  },
  computed: {
    urls() {
      return this.publicData.urls;
    },
    params() {
      return this.publicData.params;
    },
    editItemGuid() {
      return this.publicData.editItemGuid;
    },
    editItemIndex() {
      for (var i in this.form.items) {
        if (this.form.items[i].guid == this.editItemGuid) {
          return i;
        }
      }
      return undefined;
    },
    groups() {
      var g = [];
      for (var i in this.form.groups) {
        if (this.form.groups[i].type == "Pano") {
          g.push(this.form.groups[i]);
        }
      }
      return g;
    },
    form() {
      return this.publicData.form || {};
    },
    $v() {
      return this.publicData.$v;
    },
  },
  created() {},
  mounted() {
    this.loadWeatherTypes();
    this.loadTags();
    this.$emit("ready", {
      vm: this,
      events: {
        proTips: {
          target: "proTips",
          name: "show",
        },
        changeWeather: {
          target: "Data",
          name: "formChage",
          arg: "weather",
        },
        deleteItem: {
          target: "Data",
          name: "deleteItem",
        },
        addItem: {
          target: "Data",
          name: "addItem",
        },
        postprocessing: {
          name: "renewSence",
          arg: "postprocessing",
        },
        block: {
          name: "renewSence",
          arg: "block",
        },
        cover: {
          name: "renewSence",
          arg: "cover",
        },
        view: {
          name: "renewSence",
          arg: "view",
        },
        hotspot: {
          name: "renewSence",
          arg: "hotspot",
        },
      },
      funcs: {
        show: this.show,
        addItemStart: this.addItemStart,
      },
    });
  },
  destroyed() {},
  methods: {
    modelReady({ funcs }) {
      this.modelShow = funcs.show;
      this.modelHide = funcs.hide;
    },
    show() {
      if (this.modelShow) {
        this.modelShow();
      } else {
        alert("模态框还没有准备好");
      }
    },
    deleteItemModelReady({ funcs }) {
      this.deleteItemModelShow = funcs.show;
      this.deleteItemModelHide = funcs.hide;
    },
    deleteItemshow() {
      if (this.deleteItemModelShow) {
        this.deleteItemModelShow();
      } else {
        alert("模态框还没有准备好");
      }
    },
    onhide() {
      this.$emit("next");
    },
    groupGUIDChange() {
      this.getFunc({ target: "Data", name: "updateTour" })(null, this.editItemGuid, null);
    },
    deleteItem(guid) {
      this.$emit("deleteItem", guid);
      this.deleteItemModelHide();
      this.modelHide();
    },
    addItemStart({ index, groupGuid }) {
      var p = new Promise((resolve, reject) => {
        this.PanoMultipleLibOnSubmit = (files) => {
          resolve(files);
        };
        setTimeout(() => {
          this.$refs.PanoMultipleLib.showDialog();
        }, 1);
      }).then((files) => {
        if (files.length == 0) {
          return;
        }
        for (var i in files) {
          var d = {};
          d.linkItem = files[i];
          d.title = files[i].title;
          d.weather = files[i].weather || "无";
          d.postprocessing = files[i].postprocessing;
          d.vLookAt = files[i].vLookAt || 0;
          d.vLookAtMax = files[i].vLookAtMax || 0;
          d.vLookAtMin = files[i].vLookAtMin || 0;
          this.$emit("addItem", { index: index + parseInt(i), groupGuid, item: d });
        }
        this.PanoMultipleLibOnSubmit = () => {};
        this.getFunc({
          target: "GroupItem",
          name: "setEditItem",
        })(this.form.items[index].guid);
        this.$forceUpdate();
      });
    },
    loadWeatherTypes() {
      if (!this.urls) {
        setTimeout(this.loadWeatherTypes, 300);
        return;
      }
      this.$axios
        .get(this.urls.weatherTypes())
        .then((response) => {
          //console.log(response)
          this.weathers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadTags() {
      if (!this.urls) {
        setTimeout(this.loadTags, 300);
        return;
      }
      var tagtype = "全景";
      this.$axios
        .get(this.urls.publicTags(), { params: { type: tagtype } })
        .then((response) => {
          this.publicTags = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get(this.urls.privateTags(), { params: { type: tagtype, ...this.params } })
        .then((response) => {
          this.privateTags = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logoUploadFinished(file) {
      console.log(file);
      this.form.logo = file.logo;
    },
    deleteLogo() {
      if (!this.urls) {
        setTimeout(this.deleteLogo, 300);
        return;
      }
      this.$axios.delete(this.urls.panoLogo()).then(() => {
        this.form.logo = null;
      });
    },
    showItemFileLibDialog() {
      this.$refs.ItemFileLib.showDialog();
    },
    ItemFileLibSelected(select) {
      this.form.items[this.editItemIndex].file = select;
    },
    showItemAudioLibDialog() {
      this.$refs.ItemAudioLib.showDialog();
    },
    ItemAudioLibSelected(select) {
      this.form.items[this.editItemIndex].audio = select;
    },
    tagsCheckOnChange(list) {
      console.log(list);
      this.form.tags = [...list];
    },
  },
};
</script>
<style scoped>
.introText {
  color: white;
  text-align: justify;
}
</style>
